import React from 'react';
import ReactDOM from 'react-dom';
import { rootDomain } from './store/root-domain';
import { SharedHostGateway } from '@invent/platform-api';
import { baseHttpClient } from '@invent/http-client';
import { createInspector, addEffect, addEvent, addStore } from 'effector-inspector';

// bootstrap
// The import is necessary because the result of setting eager: true doesn't work as expected
// This is the equivalent solution
import '@invent/wlabel-shared-components';
import { updateFavicon } from '@/src/domains/branding/utils';
//
const SANDBOX_BOOTSTRAP_URI = 'https://icp-api.dev.apptrium.cloud/bootstrap';
const UAT_BOOTSTRAP_URI = 'https://icp-api.uat.apptrium.cloud/bootstrap';
const PROD_BOOTSTRAP_URI = 'https://icp-api.apptrium.cloud/bootstrap';

function selectBootstrapURI() {
  const origin = window.location.origin;
  if (origin.includes('.sandbox.apptrium.cloud')) {
    return SANDBOX_BOOTSTRAP_URI;
  }
  if (origin.includes('.rc.apptrium.cloud')) {
    return UAT_BOOTSTRAP_URI;
  }
  return PROD_BOOTSTRAP_URI;
}

// TODO - find a better place for this probably
if (process.env.NODE_ENV !== 'production') {
  // @ts-expect-error
  window.API_BASE_URL = API_BASE_URL; // widgets-clic/widgets-clic-de-datagrid

  // @ts-expect-error
  window.STORE_BASE_URL = STORE_BASE_URL; // platform-api

  // @ts-expect-error
  window.CUSTOMIZATION_BE = CUSTOMIZATION_BE; // widgets-clic/widgets-clic-apex-formio

  // @ts-expect-error
  window.SIGNOUT_TIMEOUT = SIGNOUT_TIMEOUT; // widgets-clic/widgets-clic-side-nav

  // @ts-expect-error
  window.REPORTER_TOKEN = REPORTER_TOKEN; // widgets-clic/widgets-clic-userback

  // @ts-expect-error
  window.DEV_FEDERATION = DEV_FEDERATION; // @invent/platform-api

  try {
    // @ts-expect-error
    window.CUSTOM_ENV = typeof CUSTOM_ENV === 'string' ? JSON.parse(CUSTOM_ENV) : CUSTOM_ENV;
  } catch {
    // @ts-expect-error
    window.CUSTOM_ENV = {};
  }

  window.CUSTOMER_NAME = CUSTOMER_NAME;

  updateFavicon(CUSTOMIZATION_BE);
}

// It is important to hold this code - or any rootDomain-dependent invocations -
// in main.tsx (right at the app entrypoint)
// so rootDomain will be initialized and shared into the scope before lib dependencies
// arrive in runtime, trying to locate if rootDomain is shared
if (process.env.NODE_ENV !== 'production') {
  rootDomain.onCreateStore(addStore);
  rootDomain.onCreateEffect(addEffect);
  rootDomain.onCreateEvent(addEvent);

  createInspector({ trimDomain: 'app' });
}

window.sharedHostGateway = new SharedHostGateway({});

// async imports are here to delay appearance of modules that could have unresolved dependencies
async function main() {
  const { catchAndNotify, showNotification } = await import('./handlers');
  window.sharedHostGateway.registerNotifications({ catchAndNotify, showNotification });
  if (!CUSTOMIZATION_BE && !API_BASE_URL) {
    try {
      /*
      this hardcode placed here to support new dev env.
      We are expecting that there will be no more then 2
      'bootstrap' servers (cockpit-switchboard-api)
     */
      const bootstrapUrl = selectBootstrapURI();
      const envConfig = await fetch(bootstrapUrl);
      const envConfigJson = await envConfig.json();
      updateFavicon(envConfigJson.CUSTOMIZATION_BE);
      sessionStorage.setItem('tenentId', envConfigJson.TENENT_KEY);
      WIDGET_STORE_API_URL = envConfigJson.WIDGET_STORE_API_URL;
      API_BASE_URL = envConfigJson.API_BASE_URL;
      CUSTOMER_NAME = envConfigJson.CUSTOMER_NAME;
      CUSTOMIZATION_BE = envConfigJson.CUSTOMIZATION_BE;
      OIDC_AUTH_ENDPOINT = envConfigJson.OIDC_AUTH_ENDPOINT;
      OIDC_BASE_URL = envConfigJson.OIDC_BASE_URL;
      OIDC_CLIENT_ID = envConfigJson.OIDC_CLIENT_ID;
      OIDC_END_SESSION_ENDPOINT = envConfigJson.OIDC_END_SESSION_ENDPOINT;
      OIDC_PARAMETERS = envConfigJson.OIDC_PARAMETERS;
      OIDC_REDIRECT_URL = envConfigJson.OIDC_REDIRECT_URL;
      SHOW_WIDGET_PREVIEW = envConfigJson.SHOW_WIDGET_PREVIEW;
      SIGNOUT_TIMEOUT = envConfigJson.SIGNOUT_TIMEOUT;
      STORE_BASE_URL = envConfigJson.STORE_BASE_URL;
      Object.keys(envConfigJson).forEach((key) => (window[key as any] = envConfigJson[key]));
    } catch (e) {
      catchAndNotify({
        message: 'Failed to get project configuration. Contact invent admin',
        traceId: 'bootstrap-1'
      });
    }
  } else if (typeof TENENT_KEY !== 'undefined') {
    sessionStorage.setItem('tenentId', TENENT_KEY);
  }

  baseHttpClient.interceptors.request.add((...args) => {
    const [_apiUrl, options] = args;
    const apiUrl = _apiUrl
      .replace('_internal_api_', API_BASE_URL)
      .replace('_fe_be_api_', CUSTOMIZATION_BE);
    return [apiUrl, options];
  });

  const App = await import('./app');
  ReactDOM.render(<App.default />, document.getElementById('root'));
}

main();
